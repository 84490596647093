<template>
    <div class="card">
        <div class="card-header border-0 pt-6 bg-white row m-0 sticky">
            <div class="d-flex p-0 mb-2">
                <div class="card-title">
                    <span class="text-gray-500 fw-bolder text-hover-primary rounded-1 cursor-pointer me-6 fs-6" @click="router.push({ name: 'stok_item' })"><i class="fas fa-arrow-left me-2 text-gray-500 fw-bolder"></i>BACK</span>
                    <form class="filter d-flex align-items-center position-relative fs-6 text-gray-600" @submit.prevent>
                        <div class="filter-item">
                            <el-input v-model="filter.search" placeholder="Search" clearable />
                        </div>
                        <div class="row gx-2">
                            <div class="col">
                                <button type="submit" class="btn btn-icon btn-light-primary" @click="onFilter()"><i class="fas fa-search"></i></button>
                            </div>
                            <div class="col">
                                <button class="btn btn-icon btn-light-danger" @click="resetFilter()"><i class="fas fa-times"></i></button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="card-toolbar" style="margin-left:auto">
                    <div class="d-flex justify-content-end" data-kt-table-toolbar="base">
                        <div v-show="tableStore.length !== dynamicStore.length" @click="showStore()" class="badge badge-secondary cursor-pointer">Show All Store</div>
                        <button type="button" class="btn btn-light-primary me-3" data-bs-toggle="modal" data-bs-target="#kt_customers_export_modal" v-show="false">
                            <span class="svg-icon svg-icon-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.3" x="12.75" y="4.25" width="12" height="2" rx="1" transform="rotate(90 12.75 4.25)" fill="black" />
                                    <path d="M12.0573 6.11875L13.5203 7.87435C13.9121 8.34457 14.6232 8.37683 15.056 7.94401C15.4457 7.5543 15.4641 6.92836 15.0979 6.51643L12.4974 3.59084C12.0996 3.14332 11.4004 3.14332 11.0026 3.59084L8.40206 6.51643C8.0359 6.92836 8.0543 7.5543 8.44401 7.94401C8.87683 8.37683 9.58785 8.34458 9.9797 7.87435L11.4427 6.11875C11.6026 5.92684 11.8974 5.92684 12.0573 6.11875Z" fill="black" />
                                    <path d="M18.75 8.25H17.75C17.1977 8.25 16.75 8.69772 16.75 9.25C16.75 9.80228 17.1977 10.25 17.75 10.25C18.3023 10.25 18.75 10.6977 18.75 11.25V18.25C18.75 18.8023 18.3023 19.25 17.75 19.25H5.75C5.19772 19.25 4.75 18.8023 4.75 18.25V11.25C4.75 10.6977 5.19771 10.25 5.75 10.25C6.30229 10.25 6.75 9.80228 6.75 9.25C6.75 8.69772 6.30229 8.25 5.75 8.25H4.75C3.64543 8.25 2.75 9.14543 2.75 10.25V19.25C2.75 20.3546 3.64543 21.25 4.75 21.25H18.75C19.8546 21.25 20.75 20.3546 20.75 19.25V10.25C20.75 9.14543 19.8546 8.25 18.75 8.25Z" fill="#C4C4C4" />
                                </svg>
                            </span>
                            Export
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body pt-0 tab-content">
            <div ref="tableRef" class="table-fixed-header">
                <table class="table table-rounded table-row-dashed table-hover" :class="{'overlay': loadingTable, 'overlay-block': loadingTable}">
                    <thead>
                        <tr class="text-start fw-bolder fs-7 text-gray-400 border-gray-200 text-uppercase">
                            <th>#</th>
                            <th>Gambar</th>
                            <th>Tipe Produk</th>
                            <th>Kategori</th>
                            <th>Artikel</th>
                            <th>Warna</th>
                            <th>Ukuran</th>
                            <th class="text-end" v-for="(item, i) in tableStore" :key="i">
                                <span>{{ item.store_name.replaceAll('NIMCO', '') }}</span>
                                <span v-show="false" @click="hideStore(i)" class="badge badge-secondary cursor-pointer ms-2"><small>hide</small></span>
                            </th>
                            <th class="text-end w-150px">Total</th>
                            <th class="text-end w-150px">Action</th>
                        </tr>
                    </thead>
                    <tbody class="fw-bold text-gray-600" v-for="(item, i) in tableData" :key="i">
                        <tr v-if="!loadingTable && tableData.length == 0">
                            <td colspan="4">No data</td>
                        </tr>
                        <tr @click="stokPolosVariant(item.artikel, i)" :id="'parent_'+i" class="cursor-pointer">
                            <td scope="row">{{ item.index }}</td>
                            <td><img class="tbl-image" :src="img_url_product+item.gambar" @click.stop.prevent="viewImage(item)"/></td>
                            <td>{{ item.tipe_produk }}</td>
                            <td>{{ item.category }}</td>
                            <td>{{ item.artikel }}</td>
                            <td>{{ item.color }}</td>
                            <td>{{ item.size.length > 1 ? item.size.replaceAll(',', ', ') : item.size }}</td>
                            <td class="text-end w-125px" v-for="(data, i) in tableStore" :key="i">{{ item.store.find(o => o.store_id == data.store_id).qty }}</td>
                            <td class="text-end w-150px">{{ item.total }}</td>
                            <td class="indicator-arrow text-end w-150px">
                                <button class="btn btn-primary btn-sm">
                                    <i class="ms-2 fas fa-chevron-down"></i>
                                    <span class="spinner-border spinner-border-sm loadingInside d-none" role="status"></span>
                                </button>
                            </td>
                        </tr>
                        <tr class="table-dropdown pb-5" :id="'child_'+i">
                            <td colspan="15" style="padding-right: 0 !important;">
                                <table class="table table-hover">
                                    <thead>
                                        <tr class="text-start fw-bolder fs-7 gs-0 text-gray-400 border-bottom-2 border-gray-200 text-uppercase">
                                            <th scope="col" class="w-50px"></th>
                                            <th scope="col" class="w-250px">Nama</th>
                                            <th scope="col" class="w-125px">SKU</th>
                                            <th scope="col" class="w-125px">Artikel</th>
                                            <th scope="col" class="w-125px">Warna</th>
                                            <th scope="col" class="text-center w-125px">Ukuran</th>
                                            <th class="text-end w-125px" v-for="(data, i) in tableStore" :key="i">
                                                {{ data.store_name.replaceAll('NIMCO', '') }}
                                            </th>
                                            <th scope="col" class="text-end w-125px">Total</th>
                                            <th scope="col" class="text-end w-125px">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody class="fw-bold text-gray-600">
                                        <tr v-for="(subItem, j) in tableSub" :key="j">
                                            <td><span class="bullet bg-gray-500 w-5px h-5px rounded"></span></td>
                                            <td scope="col">{{ subItem.name }}</td>
                                            <td scope="col">{{ subItem.sku }}</td>
                                            <td scope="col">{{ subItem.artikel }}</td>
                                            <td scope="col">{{ subItem.color }}</td>
                                            <td scope="col" class="text-center">{{ subItem.size }}</td>
                                            <td class="text-center" v-for="(data, i) in tableStore" :key="i">
                                                <div :title="'Add stock to '+data.store_name" class="ms-auto w-50px p-1 cursor-pointer bg-gray-200" @click="addStock(subItem, subItem.store.find(o => o.store_id == data.store_id))">{{ subItem.store.find(o => o.store_id == data.store_id).qty }}</div>
                                            </td>
                                            <td scope="col" class="text-end">{{ subItem.total }}</td>
                                            <td scope="col" class="text-end">
                                                <div class="d-flex justify-content-end">
                                                   <button @click="addStock(subItem, subItem.store.find(o => o.store_id == 1))" class="btn btn-primary btn-sm">
                                                        <i class="ms-2 fas fa-plus"></i>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                    <div class="overlay-layer card-rounded bg-dark bg-opacity-5" v-if="loadingTable">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </table>
            </div>
            <div class="text-center my-3">
              <el-pagination
                background
                layout="prev, pager, next"
                :page-count="totalPage"
                :total="totalPage"
                @next-click="handlePageChange"
                @prev-click="handlePageChange"
                @current-change="handlePageChange"
                @update:current-page="currentPage"
              />
            </div>
        </div>

        <el-dialog v-model="modalVisible" :title="tempData.store_name" custom-class="modal-stok" :close-on-click-modal="true" width="450px">
            <form @submit.prevent="onSubmit">
                <div class="me-n7 pe-7">
                    <div class="fv-row mb-7">
                        <div class="mb-4"><span class="fs-6 text-uppercase">stok saat ini: {{ tempData.qty }}</span></div>
                    </div>
                    <div class="fv-row mb-7">
                        <label class="required fw-bold mb-2">
                            <span>{{ tempData.name }}</span>
                        </label>
                        <input type="number" class="form-control" placeholder="Tambah" v-model="tempData.so_qty" />
                    </div>
                    <div class="fv-row mb-7">
                        <label class="required fs-6 fw-bold mb-2">Keterangan</label>
                        <input type="text" class="form-control" placeholder="Isi keterangan" v-model="tempData.note" />
                    </div>
                </div>
                <div class="mt-4 mb-2 text-center">
                    <button type="button" class="btn btn-light me-3" @click="modalVisible = false">Cancel</button>
                    <button
                        type="submit"
                        @click="onSubmit"
                        class="btn btn-primary"
                        data-bs-toggle="button"
                        :disabled="loadingButton"
                        :data-kt-indicator="loadingButton ? 'on' : 'off'"
                    >
                        <span class="indicator-label"> Submit </span>

                        <span class="indicator-progress">
                            Please wait...
                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                </div>
            </form>
        </el-dialog>
        
        <el-dialog v-model="getZoomImage.visibility" :title="getZoomImage.title" width="50%" lock-scroll top="5vh">
            <inner-image-zoom :src="getZoomImage.src"/>
        </el-dialog>
    </div>
</template>

<style>
    .modal-stok .el-dialog__body {
        padding-top: 0 !important;
    }
</style>

<script>
import { defineComponent, onMounted, ref, reactive, watchEffect, watch, computed, onUpdated } from "vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { formatIDR } from "@/helpers/functions";
//import TableLite from 'vue3-table-lite/src/components/TableLite.vue'
//import Datatable from "@/components/kt-datatable/KTDatatable.vue"
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { ErrorMessage, Field, Form } from "vee-validate";
import InnerImageZoom from 'vue-inner-image-zoom';
import { InfoFilled } from '@element-plus/icons-vue'
import { ElLoading } from 'element-plus'
import { ElMessage, ElMessageBox } from 'element-plus'
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
    components: { 
        //Datatable,
        //Field,
        //Form,
        //ErrorMessage,
        'inner-image-zoom': InnerImageZoom,
    },
    setup() {
        const img_url_product = ref(process.env.VUE_APP_IMAGE_PRODUCTS)

        const route = useRoute()
        const router = useRouter()

        const loadingTable = ref(false);
        const loadingSubTable = ref(false);
        const loadingButton = ref(false);

        const submitButton = ref(null);
        const tableRef = ref(null)

        const tableData = reactive([]);
        const tableSub = reactive([]);
        const totalRecordCount = ref(0);
        const dynamicStore = reactive([])
        const tableStore = reactive([])
        
        const modalVisible = ref(false)
        const modalTitle = ref('')
        
        const listStore = reactive([])

        const tempData = reactive({
            name: null,
            sku: null,
            raw_product_id: null,
            store_id: null,
            stor_name: null,
            qty: null,
            total_qty: null,
            so_qty: null,
            color_id: null,
            note: '',
        })

        const filterActive = ref(false)
        const filter = reactive({
            search: null,
        })

        // PAGINATION
        const currentPage = ref(1)
        const totalPage = ref(0)

        const handlePageChange = async (page) => {
          loadingTable.value = true
          tableRef.value && (tableRef.value.scrollTop = 0) // Reset scroll position
          await getData(page)
          //loadingTable.value = false
        }

        const addStock = (data, store) => {
            modalVisible.value = true
            tempData.raw_product_id = data.raw_product_id
            tempData.name = data.name + ' (' + data.sku + ')';
            tempData.sku = data.sku
            tempData.qty = store.qty
            tempData.store_name = store.store_name
            tempData.store_id = store.store_id
            tempData.total_qty = data.total
            tempData.color_id = data.color_id
            tempData.note = "";

            rowScrollPosition.value = tableRef.value.scrollTop
            htmlScrollPosition.value = document.querySelector('html').scrollTop
        }

        const hideStore = (index) => {
            try {

                if(tableStore.length == 1) {
                    throw new Error("Tidak bisa menyembunyikan semua store.")
                }

                tableStore.splice(index, 1);

            } catch(e) {
                Swal.fire({
                    text: e.message,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000
                });

                //console.error(e)
            }  
        }

        const showStore = () => {
            try {
                Object.assign(tableStore, dynamicStore)

            } catch(e) {
                Swal.fire({
                    text: e.message,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000
                });

                console.error(e)
            }
        }

        const onFilter = async () => {
            if(Object.values(filter).every(o => o == null || o.length == 0)) {
                return
            }

            filterActive.value = false

            loadingTable.value = true

            await getData()

            Object.values(filter).every(o => o == null || o.length == 0)
                ? filterActive.value = false
                : filterActive.value = true
        }

        const resetFilter = async () => {
          if(Object.values(filter).every(o => o == null || o.length == 0)) {
            //return
          }

          loadingTable.value = true
          filterActive.value = false

          Object.keys(filter).map(key => filter[key] = null)

          await getData()
        }

        const onSubmit = async () => {
            let setData = {
                raw_product_id: tempData.raw_product_id,
                store_id: tempData.store_id,
                qty: tempData.so_qty,
                note: tempData.note,
            }

            loadingButton.value = true
            tableSub.splice(0)

            await ApiService.setHeader();
            await ApiService.post("inventory/create_so_item", setData)
            .then(async({ data }) => {
                loadingButton.value = false
                modalVisible.value = false

                ApiService.setHeader();
                ApiService.get("inventory/stok_item_detail/"+route.params.kode+'/'+tempData.color_id)
                .then(({ data }) => {
                    totalRecordCount.value = data.length
                    Object.assign(tableSub, data)
                })
                .catch(({ response }) => {
                    console.log('Error getting variant');
                });

                Swal.fire({
                    title: data.messages,
                    icon: "success",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000,
                })

                tempData.so_qty = null
                tempData.note = ''

                await getData()
                await stokPolosVariant(rowData.value, rowIndex.value)
                currentRowView(rowIndex.value)

                loadingButton.value = false
            })
            .catch(({ response }) => {
                Swal.fire({
                    text: "Failed Create New Stock Opname",
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000
                });

                loadingButton.value = false
            });

            modalVisible.value = false
        }

        const getZoomImage = reactive({
            title: '',
            src: '',
            visibility: false,
        })

        const viewImage = (data) => {
            getZoomImage.visibility = true
            getZoomImage.title = data.name
            getZoomImage.src = img_url_product.value+data.gambar
        }

        const rowIndex = ref(null)
        const rowData = ref(null)
        const rowScrollPosition = ref(null)
        const htmlScrollPosition = ref(null)

        const currentRowView = (index) => {
            tableRef.value.scrollTop = rowScrollPosition.value
            document.querySelector('html').scrollTop = htmlScrollPosition.value
        }

        const stokPolosVariant = async (artikel, index) => {
            try {
                let kode = route.params.kode

                // get current row view
                rowIndex.value = index
                rowData.value = artikel
                
                tableSub.splice(0)
                loadingSubTable.value = true

                document.getElementById('parent_'+index).querySelector('.loadingInside').classList.remove('d-none');
                document.querySelector('#parent_'+index+' > .indicator-arrow i').classList.add('d-none');

                document.getElementById('parent_'+index).closest('#parent_'+index).classList.toggle('table-active');
                document.getElementById('child_'+index).classList.toggle('table-active');

                // Fetch data
                if(document.querySelector('#parent_'+index+' > .indicator-arrow i').classList.contains('fa-chevron-down')) {
                    await ApiService.get("inventory/stok_item_detail/"+kode+'/'+artikel)
                    .then(({ data }) => {
                        if(data.error) {
                            throw new Error('Error getting product variant')
                        }
                        totalRecordCount.value = data.length
                        Object.assign(tableSub, data)
                    })
                    .catch(({ response }) => {
                        throw new Error('Error getting product variant')
                    });
                }

                document.getElementById('child_'+index).classList.toggle('table-dropdown-show');

                if(document.getElementById('child_'+index).classList.contains('table-dropdown-show')) {
                    document.querySelector('#parent_'+index+' > .indicator-arrow i').classList.add('fa-chevron-up');
                    document.querySelector('#parent_'+index+' > .indicator-arrow i').classList.remove('fa-chevron-down');
                } else {
                    document.querySelector('#parent_'+index+' > .indicator-arrow i').classList.add('fa-chevron-down');
                    document.querySelector('#parent_'+index+' > .indicator-arrow i').classList.remove('fa-chevron-up');
                }

                tableData.filter((o, i) => {
                    if(i != index) {
                        document.getElementById('child_'+i).classList.remove('table-dropdown-show');
                        document.getElementById('parent_'+i).closest('#parent_'+i).classList.remove('table-active');
                        document.querySelector('#parent_'+i+' > .indicator-arrow i').classList.remove('fa-chevron-up');
                        document.querySelector('#parent_'+i+' > .indicator-arrow i').classList.add('fa-chevron-down');
                    }
                })

            } catch(e) {
                Swal.fire({
                    text: e.message,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000
                });
            }

            loadingSubTable.value = false
            document.getElementById('parent_'+index).querySelector('.loadingInside').classList.add('d-none');
            document.querySelector('#parent_'+index+' > .indicator-arrow i').classList.remove('d-none');
            
        }

        const getData = async (page = 1) => {
            try {
                tableData.splice(0)
                loadingTable.value = true

                await ApiService.post("inventory/stok_item_detail/"+route.params.kode+'?page='+page, { filter: filter })
                .then(({ data }) => {
                    totalPage.value = data.data.pagination.total_page
                    Object.assign(tableData, data.data.data)

                    let tipe_produk = route.params.kode.split('-')[2].toUpperCase();

                    if(tipe_produk.toLowerCase() == 'display') {
                        img_url_product.value = process.env.VUE_APP_IMAGE_DESAINS;
                    } else {
                        img_url_product.value = process.env.VUE_APP_IMAGE_PRODUCTS;
                    }

                    setCurrentPageBreadcrumbs(`${data.data.data[0].category} - ${data.data.data[0].brand_name.toUpperCase()} - ${data.data.data[0].vendor} (${tipe_produk.toLowerCase() == 'mto' ? 'MTO' : tipe_produk.toLowerCase() == 'rtw' ? 'RTW' : 'DISPLAY'})`, ["Stok Item", data.data.data[0].category+' - '+ data.data.data[0].brand_name + ' - ' + data.data.data[0].vendor]);
                })
                .catch(({ response }) => {
                    console.error(response);
                });
                
                //let cek = Object.keys(Object.assign({}, ...tableData)).filter((o, i) => o.includes('_st'))
                Object.assign(dynamicStore, tableData[0].store)

                // Get table dynamic cols of store
                Object.assign(tableStore, tableData[0].store)

            } catch(e) {
                console.error(e)
            }

            loadingTable.value = false
        }

        onMounted( async () => {
            await getData();
        })

        return {
            tableRef, 
            filter, filterActive, onFilter, resetFilter,
            modalVisible, modalTitle, onSubmit, addStock, 
            loadingTable, loadingSubTable, loadingButton, img_url_product,
            tableData, totalRecordCount, tempData, dynamicStore, tableStore,
            viewImage, getZoomImage,
            submitButton,
            formatIDR,
            totalPage, currentPage, handlePageChange,
            InfoFilled,
            route, router,
            tableSub, stokPolosVariant,
            showStore, hideStore,
        }
    }
})
</script>