export function input(angka) {
    angka = angka.toString().replace(/\s/g, ""); // Remove all whitespace
  
    if (isNaN(angka)) {
      angka = angka.toString().slice(0, -1);
    }
  
    if (angka.length == 2) {
      if (angka.indexOf(".") !== 1) {
        angka = angka.replace(/^0+/, ""); // Remove 0 first
      }
    }
  
    return angka;
}


export function formatIDR(val = 0, options = {
  prefix: '',
}) {
  let angka = val == null ? 0 : val
  angka = angka.toLocaleString("ID");
  // /[^,\d] all character except
  let number_string = angka.replace(/[^,\d]/g, "").toString(),
    split = number_string.split(","),
    sisa = split[0].length % 3,
    rupiah = split[0].substr(0, sisa),
    ribuan = split[0].substr(sisa).match(/\d{3}/gi);

  let prefix = "";

  if (typeof options.prefix == undefined) {
    //rupiah = rupiah;
  }

  if (options.prefix && options.prefix.length > 0) prefix = options.prefix;
  else prefix = "";

  // Negative value
  if (parseFloat(angka) < 0) {
    prefix = "-" + prefix;
    angka = Math.abs(angka);
  }


  if (angka.length == 2) {
    if (angka.indexOf(",") !== 1) {
      rupiah = angka.replace(/^0+/, ""); // Remove 0 first
    }
  }

  // tambahkan titik jika yang di input sudah menjadi angka ribuan
  if (ribuan) {
    let separator = sisa ? "." : "";
    rupiah += separator + ribuan.join(".");
  }

  rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;

  if (typeof options === "undefined" || typeof options != "object") {
    return rupiah;
  }

  if (typeof options.unit !== undefined && options.unit == true) {
    let text = ["", "", "rb", "jt", "M", "T"];
    let input = rupiah;

    if (input.includes(",")) {
      let last_array_comma = input.split(",");
      input = last_array_comma[0].toString();
    }

    input = input.split(".");

    rupiah =
      input[0] +
      (input.length > 1 ? "," + Math.round(input[1] / 10) : "") +
      text[input.length];
  }

  return typeof prefix !== undefined ? prefix + rupiah : rupiah;
}

export function customDate(date, {
  type: type = 'datetime',
}) {
  var week = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];
  const monthNames = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];

  function zeroPadding(num, digit) {
      var zero = '';
      for(var i = 0; i < digit; i++) {
          zero += '0';
      }
      return (zero + num).slice(-digit);
  }
  
  const cd = new Date(date);
  let getDate = zeroPadding(week[cd.getDay()]) + ', ' + cd.getDate() + ' ' + zeroPadding(monthNames[cd.getMonth()]) + ' ' +  zeroPadding(cd.getFullYear(), 4);
  let getTime = zeroPadding(cd.getHours(), 2) + ':' + zeroPadding(cd.getMinutes(), 2) + ':' + zeroPadding(cd.getSeconds(), 2);

  if(type == 'undefined') {
      return getDate + ' ' + getTime
  }

  if(type == 'datetime') {
      return getDate + ' ' + getTime
  } else if(type == 'date') {
      return getDate
  } else if(type == 'time') {
      return getTime
  } else {
      return null
  }
}

export function formatPrice(value) {
    let val = "Rp "+(value/1).toFixed(0).replace('.', ',')
    return  val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
}

export function formatPriceNonSymbol(value) {
    let val = (value/1).toFixed(0).replace('.', ',')
    return  val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
}

export function pushArray(data, array) {
    for(let i=0; i<data.length; i++) {
        array.push(data[i])
    }
}

export function inputValidator(obj) {
    
    /*  USING

    const state = {
        name: {
            value: 'name',
            minlength: 3,
            maxlength: 50,
            response: ''
            type: 'text'
        },
        email: {
            value: 'ali@gmail.com',
            minlength: 3,
            maxlength: 50,
            response: '',
            type: 'email',
        },
        province: {
            value: '',
            minlength: 3,
            maxlength: 50,
            response: '',
            type: 'select',
        },
    }*/

    try {
        const validateEmail = (email) => {
            return email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        };

        let getObj = []
        Object.keys(obj).map(function(key) {
            if(obj[key].transform === 'capitalize'){
                obj[key].value.replace(/\w\S*/g, function(txt) {
                    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                });
            }

            if(obj[key].value == '' || obj[key].value == null)
                obj[key].response = 'The field '+key+' is required'
            else if(obj[key].value.length < obj[key].minlength && obj[key].type !== 'select')
                obj[key].response = 'The '+key+' field must be at least '+obj[key].minlength+' characters in length'
            else if(obj[key].value.length > obj[key].maxlength && obj[key].type !== 'select')
                obj[key].response = 'The '+key+' field must be no more than '+obj[key].maxlength+' characters in length'   
            else if(obj[key].type === 'email' && obj[key].value != '')
                !validateEmail(obj[key].value) && (obj[key].response = 'The email field must contain a valid email address')
            else{
                obj[key].response = ''
                getObj.push(JSON.parse(JSON.stringify(obj[key])))
            }
        })

        if(getObj.length === Object.keys(obj).length)
            return true

        return false
    }
    catch {
        console.log('Error occured on validator')
    }
    
}

export function validFileSize(files) {
  let valid = true
  if (files) {
    files.map(file => {
      const size = file.size / 1024 / 1024
      if (size > 10) {
        valid = false
      }
    })
  }
  return valid
}

export function validFileType(files) {
  let valid = true
  if (files) {
    files.map(file => {
      if (!['text/plain'].includes(file.type)) {
        valid = false
      }
    })
  }
  return valid
}